// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'
require('prismjs/themes/prism.css')
require('katex/dist/katex.min.css')


export const onServiceWorkerUpdateReady = () => window.location.reload();


// https://stackoverflow.com/questions/58225352/need-to-refresh-previously-vistied-gatsby-site-to-view-changes